<script setup>
import DamageDot from './DamageDot.vue';

defineProps({
  src: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  dots: {
    type: Array,
    default: () => [],
  },
  imageBinds: {
    type: Object,
    default: () => ({}),
  },
});
</script>

<template>
  <div class="DamageSide">
    <div class="position-relative">
      <img
        :src="src"
        class="DamageSide__image w-100"
        ondragstart="return false;"
        v-bind="imageBinds"
        v-on="$listeners"
      >
      <DamageDot
        v-for="(dot, index) in dots"
        :key="index"
        :style="{
          top: `${dot.y}%`,
          left: `${dot.x}%`,
          transform: 'translate(-50%, -50%)',
        }"
        class="position-absolute"
      />
    </div>
    <label
      v-if="label"
      class="d-block emobg-font-weight-semibold text-center"
    >
      {{ label }}
    </label>
  </div>
</template>
