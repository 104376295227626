import { COLORS } from '@emobg/web-components';

export const INVESTIGATION_STATUS = {
  inProgress: 'in_progress',
  closed: 'closed',
};
export const INVESTIGATION_STATUS_COLORS = {
  [INVESTIGATION_STATUS.inProgress]: COLORS.warning,
  [INVESTIGATION_STATUS.closed]: COLORS.success,
};

export const INVESTIGATION_RESOLUTION_STATUS = {
  identified: 'identified',
  notIdentified: 'not_identified',
};

export const INVESTIGATION_RESOLUTION_STATUS_COLORS = {
  [INVESTIGATION_RESOLUTION_STATUS.identified]: COLORS.success,
  [INVESTIGATION_RESOLUTION_STATUS.notIdentified]: COLORS.danger,
};
