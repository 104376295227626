var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "DamageSide" }, [
    _c(
      "div",
      { staticClass: "position-relative" },
      [
        _c(
          "img",
          _vm._g(
            _vm._b(
              {
                staticClass: "DamageSide__image w-100",
                attrs: { src: _vm.src, ondragstart: "return false;" },
              },
              "img",
              _vm.imageBinds,
              false
            ),
            _vm.$listeners
          )
        ),
        _vm._l(_vm.dots, function (dot, index) {
          return _c(_setup.DamageDot, {
            key: index,
            staticClass: "position-absolute",
            style: {
              top: `${dot.y}%`,
              left: `${dot.x}%`,
              transform: "translate(-50%, -50%)",
            },
          })
        }),
      ],
      2
    ),
    _vm.label
      ? _c(
          "label",
          { staticClass: "d-block emobg-font-weight-semibold text-center" },
          [_vm._v(" " + _vm._s(_vm.label) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }