var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.status
    ? _c("ui-badge", {
        staticClass: "InvestigationStatusBadge",
        attrs: {
          color: _setup.COLORS[_vm.status] || _vm.GRAYSCALE.ground,
          text: _setup.sentenceCase(_vm.status),
        },
      })
    : _c("span", [_vm._v(_vm._s(_vm.FALLBACK_MESSAGE.dash))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }